import React, { Fragment } from "react";

import { Redirect } from 'react-router-dom';
import AppHeader from "../Layout/AppHeader/";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <div className="app-main__outer">
            <h1 className="app-main__inner text-center">
              Not Found
            </h1>
          </div>
        </div>
      </Fragment>      
    );
  }
}

export default NotFound;
