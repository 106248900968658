import React, { lazy } from "react";

const Home = lazy(() => import("../Home"));
const Quizes = lazy(() => import("../Quizes"));
const Login = lazy(() => import("../Login"));
const Marksheet = lazy(() => import("../Marksheet"));
const Leaderboard = lazy(() => import("../Leaderboard"));
const PDFViewer = lazy(() => import("../Leaderboard/PDFViewer"));

const Dashboard = lazy(() => import("../Admin/DashBoard"));
const Groups = lazy(() => import("../Admin/Groups"));
const Quizzes = lazy(() => import("../Admin/Quizzes"));
const GeneralInfo = lazy(() => import("../Admin/GeneralInfo"));
const JamatUserList = lazy(() => import("../Admin/Jamaat"));
const MarahalReport = lazy(() => import("../Admin/MarahalReport"));
const MarhalDetails = lazy(() => import("../Admin/MarhalaDetails"));

export {
	Dashboard,
	Home,
	Quizes,
	Login,
	Marksheet,
	Groups,
	Quizzes,
	GeneralInfo,
	JamatUserList,
	MarahalReport,
	MarhalDetails,
	Leaderboard,
	PDFViewer
};