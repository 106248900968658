import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { rolesConfig } from '../config/roles';
import * as Routes from './index';
import NotFound from '../Components/NotFound';
import { getToken, isAdmin } from './Common';

class PrivateRoutes extends Component {
	state = { allowedRoutes: [], redirect: false };

	componentDidMount() {
    if(!getToken()) {
      this.setState({ redirect: true });
    }
    if(Math.abs(isAdmin()) === 1) {
      let roles = ['students','admin'];
      roles = ['common', ...roles];
			let allowedRoutes = roles.reduce((acc, role) => {
				return [...acc, ...rolesConfig[role].routes];
			}, []);

			// For removing duplicate entries, compare with 'url'.
			allowedRoutes = uniqBy(allowedRoutes, 'url');
			this.setState({ allowedRoutes });
    } else {
      let roles = ['students'];
      roles = ['common', ...roles];
      let allowedRoutes = roles.reduce((acc, role) => {
        return [...acc, ...rolesConfig[role].routes];
      }, []);

      // For removing duplicate entries, compare with 'url'.
      allowedRoutes = uniqBy(allowedRoutes, 'url');
      this.setState({ allowedRoutes });
    }
    
	}

	render() {
    if (this.state.redirect) {
      return <Redirect to='login' />
    }
		return (
			<Fragment>
				<Switch>
					{this.state.allowedRoutes.map((route) => (
						<Route
							exact
							key={route.url}
							component={Routes[route.component]}
							path={`${this.props.match.path}${route.url}`}
						/>
					))}
					{<Route component={NotFound} />}
				</Switch>
			</Fragment>
		);
	}
}

export default PrivateRoutes;