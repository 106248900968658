// component's config object.
const components = {
	home_: {
		component: 'Home',
		url: 'dashboard',
		title: 'Home',
		icon: 'menu',
		module: 1
	},
	home: {
		component: 'Home',
		url: '/',
		title: 'Home',
		icon: 'menu',
		module: 1
	},
	admin: {
		component: 'Dashboard',
		url: 'admin',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	admin_groups: {
		component: 'Groups',
		url: 'admin/groups',
		title: 'Groups',
		icon: 'menu',
		module: 1
	},
	admin_quizzes: {
		component: 'Quizzes',
		url: 'admin/quizzes',
		title: 'Quizzes',
		icon: 'menu',
		module: 1
	},
	admin_general_info: {
		component: 'GeneralInfo',
		url: 'admin/general-info',
		title: 'GeneralInfo',
		icon: 'menu',
		module: 1
	},
	admin_jamat_report: {
		component: 'JamatUserList',
		url: 'admin/jamat-report',
		title: 'JamatUserList',
		icon: 'menu',
		module: 1
	},
	admin_marhala_report: {
		component: 'MarahalReport',
		url: 'admin/marhala-report',
		title: 'MarahalReport',
		icon: 'menu',
		module: 1
	},
	admin_details: {
		component: 'Dashboard',
		url: 'admin/details/:id',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	admin_jamat_report_details: {
		component: 'JamatUserList',
		url: 'admin/jamat-report/details/:id',
		title: 'JamatUserList',
		icon: 'menu',
		module: 1
	},
	admin_marhala_report_details: {
		component: 'MarahalReport',
		url: 'admin/marhala-report/details/:id',
		title: 'MarahalReport',
		icon: 'menu',
		module: 1
	},
	marksheet: {
		component: 'Marksheet',
		url: 'marksheet/:id/:slug',
		title: 'Marksheet',
		icon: 'menu',
		module: 1
	},
	leaderboard: {
		component: 'Leaderboard',
		url: 'leaderboard/:id/:slug',
		title: 'Leaderboard',
		icon: 'menu',
		module: 1
	},
	quizzes_details: {
		component: 'Quizes',
		url: 'quizzes/:id/:slug',
		title: 'Quizes',
		icon: 'menu',
		module: 1
	},
	pdf: {
		component: 'PDFViewer',
		url: 'pdf/:file',
		title: 'PDFViewer',
		icon: 'menu',
		module: 1
	},
	login: {
		component: 'Login',
		url: 'login',
		title: 'Login',
		icon: 'menu',
		module: 1 
	}
};

// modules for grouping.
const modules = {
	0: {
		title: 'Dashboard',
		icon: 'home',
		isExpendable: true
	}
};

// component's access to roles.
const rolesConfig = {
	admin: {
		routes: [
			...Object.values(components)
		]
	},
	students: {
		routes: [
			components.home,
			components.quizzes_details,
			components.login,
			components.marksheet,
			components.leaderboard,
			components.home_,
			components.pdf
		]
	},
	common: {
		routes: [
			{ 
				component: 'Login',
				url: 'login',
				title: 'Login',
				icon: 'menu',
				module: 1 
			}
		]
	}
};

export { modules, rolesConfig };