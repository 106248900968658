import React, { Fragment } from "react";

// import Ionicon from 'react-ionicons';

import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { toast, Bounce } from "react-toastify";
import avatar1 from "../../../assets/images/profile-icon.png";
import { getUser,getTalabulilmLink } from 'utils/Common';
class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  notify2 = () =>
    (this.toastId = toast(
      "You don't have any new items in your calendar for today! Go out and play!",
      {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      }
    ));

  render() {
    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    { getUser() ? 
                    <img width={42} className="rounded-circle" src={getTalabulilmLink()+'mumin_images/'+getUser().its_id+'.png'} alt=""/>
                      :
                      <img width={42} className="rounded-circle" src={avatar1} alt=""/>  
                    }
                    </DropdownToggle>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ml-3 header-user-info">
                <div className="widget-heading">
                { getUser() &&
                  getUser().name
                }  
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserBox;
