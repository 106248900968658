import { Router, Route, Switch, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";
import { getToken, isAdmin } from '../../utils/Common';
import PrivateRoutes from '../../utils/PrivateRoute';
import history from '../../utils/history';
const Dashboard = lazy(() => import("../../Admin/DashBoard"));
const Marksheet = lazy(() => import("../../Marksheet"));

const Login = lazy(() => import("../../Login"));
const AppMain = () => {
    return (
        <Fragment>
            {/* Components */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Loading, please wait . . .
                        </h6>
                    </div>
                </div>
            }>
                <Router history={history}>
                    <Switch>
                        <Route path="/login" component={Login} exact/>  
                        
                        <Route exact
                            path="/admin/tanzeem-report/:tanzeem_id"
                            render={
                                (props) => <Dashboard {...props}/>
                            }
                        />
                        <Route exact
                            path="/admin/user-marksheet/:id/:its_id"
                            render={
                                (props) => <Marksheet {...props}/>
                            }
                        />
                        <Route path="/" component={PrivateRoutes} />
                    </Switch>
                </Router>
                
                
                {/*<Route path="/login" component={Login} exact/>      
                <Route path="/" exact
                    render={
                        (props) => getToken() ? 
                        <Home {...props}/> : 
                        <Redirect to='/login'  />
                    }
                />
                <Route exact
                    path="/quizzes/:id/:slug"
                    render={
                        (props) => getToken() ? 
                        <Quizes {...props}/> : 
                        <Redirect to='/login'  />
                    }
                />
                <Route exact
                    path="/marksheet/:id/:slug"
                    render={
                        (props) => getToken() ? 
                        <Marksheet {...props}/> : 
                        <Redirect to='/login'  />
                    }
                />
                <Route exact
                    path="/admin/user-marksheet/:id/:its_id"
                    render={
                        (props) => <Marksheet {...props}/>
                    }
                />
                <Route exact
                    path="/admin"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <Dashboard {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                
                <Route exact
                    path="/admin/groups"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <Groups {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                <Route exact
                    path="/admin/quizzes"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <Quizzes {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                 <Route exact
                    path="/admin/general-info"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <GeneralInfo {...props}/> : 
                        <Redirect to='/login'  />
                    }
                />
                <Route exact
                    path="/admin/details/:id"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <Dashboard {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                <Route exact
                    path="/admin/jamat-report"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <JamatUserList {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                <Route exact
                    path="/admin/jamat-report/details/:id"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <JamatUserList {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                <Route exact
                    path="/admin/marhala-report"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <MarahalReport {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                <Route exact
                    path="/admin/marhala-report/details/:id"
                    render={
                        (props) => (getToken() && isAdmin() == 1) ? 
                        <MarahalReport {...props}/> : 
                        <Redirect to='/'  />
                    }
                />
                <Route exact
                    path="/admin/tanzeem-report/:tanzeem_id"
                    render={
                        (props) => <Dashboard {...props}/>
                    }
                />
                 <Route exact
                    path="/admin/araz/marhala-details/:tanzeem_id/:marhala_id"
                    render={
                        (props) => <MarhalDetails {...props}/>
                    }
                />*/}

            </Suspense>

            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
