import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Switch} from 'react-router-dom';

import * as serviceWorker from "./serviceWorker";

import "./assets/base.scss";
import Main from "./Main/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
        <Component />
      </Switch>
      </BrowserRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Main/Main", () => {
    const NextApp = require("./Main/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
