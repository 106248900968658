import React, { Fragment } from "react";

import {
  Nav,
  NavLink,
  NavItem,
} from "reactstrap";
import {removeUserSession, getTalabulilmLink } from 'utils/Common';
import base64 from 'react-native-base64'

class MegaMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      popoverOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      popoverOpen: !this.state.popoverOpen,
    });
  }
  logout() {
    removeUserSession();
    window.location.href = getTalabulilmLink()+'/logout.php';
  }
  state = {};

  render() {
    return (
      <Fragment>
        <Nav className="header-megamenu">
          <NavItem>
            <NavLink href='https://www.talabulilm.com/araiz' id="menu1">
              Araiz
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink href='https://istifaadah.talabulilm.com' id="menu2">
              Istifaadah
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink href='https://www.talabulilm.com/istibsaar_new' id="menu3">
              Istibsaar
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink href='https://www.talabulilm.com/tlm' id="menu4">
              Tasnifaat
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink href='https://www.talabulilm.com/minhat' id="menu5">
              Minhat Taalimiyah
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink href='#' id="menu5" onClick={(e) => {
                  this.logout()
                }} >
              Logout
            </NavLink>
          </NavItem>
        </Nav>
      </Fragment>
    );
  }
}

export default MegaMenu;
