import md5 from 'md5';
// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('userData');
  if (userStr) return JSON.parse(userStr);
  else return null;
}
export const isAdmin = () => {
	return localStorage.getItem('isAdmin');
} 
// return the token from the session storage
export const getToken = () => {
	var pairs = document.cookie.split(";");
	var cookies = {};
	for (var i=0; i<pairs.length; i++){
		var pair = pairs[i].split("=");
		cookies[(pair[0]+'').trim()] = unescape(pair.slice(1).join('='));
	}
    
	if (typeof cookies['user_its'] === "undefined") {
		return null;
	} else {
		checkUserIsAdminOrNot(cookies['user_its']) 
		if(!getUser())
			getLoginUserData(cookies['user_its']);

		return true;
	}
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('userData');
  localStorage.removeItem('isAdmin');
  removeLocalStorageAsJson();
}

export const getApiUrl = () => {
	//return 'http://localhost/react/tlb-imtihaan/imtihaan-api/api/';
	return 'https://talabulilm.com/imtihaan/imtihaan-api/api/';
}

export const getIstifadaahApiUrl = () => {
	//return 'http://localhost/react/tlb-imtihaan/imtihaan-api/api/';
	return 'https://talabulilm.com/istifaadah/api/api/';
}

export const getIstifadaahUrl = () => {
	//return 'http://localhost/react/tlb-imtihaan/imtihaan-api/api/';
	return 'https://www.talabulilm.com/istifaadah/';
}
export const getUploadUrl = () => {
	// return 'http://localhost/react/fa-talabulilm-react-app/fa-talabulilm-react-api/upload/';
	return 'https://reactjs.talabulilm.com/react-api/upload/';
}

export const getTalabulilmLink = () => {
	// return 'http://localhost/react/fa-talabulilm-react-app/fa-talabulilm-react-api/upload/';
	return 'https://www.talabulilm.com/';
}
// set the token and user from the session storage
export const setUserSession = (user) => {
	localStorage.setItem('userData', JSON.stringify(user));
}

// set the token and user from the session storage
export const setLocalStorage = (name, value) => {
	localStorage.setItem(name, value);
}
// set the token and user from the session storage
export const setLocalStorageAsJson = (name, value) => {
	localStorage.setItem(name, JSON.stringify(value));
}
// set the token and user from the session storage
export const getLocalStorage = (name) => {
	return localStorage.getItem(name) || null;
}
// set the token and user from the session storage
export const getLocalStorageAsJson = (name) => {
	const getItem = localStorage.getItem(name);
	if (getItem) return JSON.parse(getItem);
	else return null;
}
// set the token and user from the session storage
export const removeLocalStorageAsJson = (name) => {
	localStorage.removeItem(name);
}
  
export const getLoginUserData = (its_id) => {
	const idn = 'ohbat';
	const key = md5('b2dc6f66288e2fd4dacc9d180970af08'+ its_id);
	const url = getApiUrl()+'get_its_details/'+its_id+'/'+key+'/'+idn;
	fetch(url, {
		method: 'get'
	}).then((response) => response.json())
	.then((result) => {
		setUserSession(result.data);
	})
	
	return true
}

export const checkUserIsAdminOrNot = (its_id) => {
	const url = getApiUrl()+'check/admin/'+its_id;
	var returnVar = false;
	fetch(url, {
		method: 'get'
	}).then((response) => response.json())
	.then((result) => {
		if(result.response) {
			localStorage.setItem('isAdmin', 1);
		} else {
			localStorage.setItem('isAdmin', 0);
		}
	})
}